import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vuex from 'vuex'
// import "./permission"; // 用户登录验证

import './style/font'

Vue.use(Vuex)

Vue.config.productionTip = false
// 头部
import headerTop from "@/components/headerTop.vue";
Vue.component('header-top', headerTop)
// 尾部
import footerBottom from "@/components/footerBottom.vue";
Vue.component('footer-bottom', footerBottom)
// 右侧菜单
import menuCustom from "@/components/menuCustom.vue";
Vue.component('menu-custom', menuCustom)
// 面包屑
import crumbsCustom from "@/components/crumbsCustom.vue";
Vue.component('crumbs-custom', crumbsCustom)
// 翻页
import pagination from "@/components/Pagination/index";
Vue.component('pagination', pagination)
// 上传附件
import uploadFile from "@/components/uploadFile/index";
Vue.component('upload-file', uploadFile)
// 上传图片
import uploadFileImg from "@/components/uploadFile/img";
Vue.component('upload-file-img', uploadFileImg)

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

Vue.use(ElementUI)

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
