<template>
  <div class="evaluate">
    <div class="nav-evaluate flex-row txt-center cursor-pointer">
      <i class="iconfont icondizhi"></i>
      <div class="">您所在的当前位置：</div>
      <div class="tag" @click="$router.push({ path: `/` })">首页</div>
      <div class="txt-center" v-for="(item, index) in arr" :key="index">
        <div class="links">>></div>
        <div class="tag" @click="$router.push({ path: item.router })">
          {{ item.title }}
        </div>
      </div>
      <div class="flex-cell"></div>
    </div>
  </div>
</template>

<script>
import { setStore, getStore } from "@/utils/auth";
export default {
  components: {},
  props:{
			arr:{
				type: Array,
				default: []
			},
		},
  data() {
    return {};
  },
  // watch: {
  //   // "$store.state.crumbsCustom": {
  //   //   handler: function(){
  //   //     console.log(this.$store.state.crumbsCustom);
  //   //     if (this.$store.state.crumbsCustom.length) this.arr = this.$store.state.crumbsCustom;
  //   //   }
  //   // },
  //   $route (val) {
  //     console.log(val)
  //     // this.arr = this.$store.state.crumbsCustom;
  //     this.arr[0] = {router: val.path, title: val.params.txt}
  //     console.log(this.arr)
  //   }
  // },
  methods: {},
};

</script>
<style lang="scss" scoped>
.evaluate {
  background: #fff;
  margin-bottom: 10px;
  .nav-evaluate {
    border-bottom: 1px solid #ccc;
    padding: 10px 0;
    padding-left: 10px;
    font-size: 14px;
    .iconfont {
      font-size: 20px;
      color: #db3e3e;
    }
    .tag {
      color: #3d7cf0;
    }
    .links {
      margin: 0 10px;
    }
  }
}
</style>
