<template>
  <div id="pagination">
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="curPage"
      :page-size="perPage"
      background
      layout="prev, pager, next"
      :total="total"
    />
  </div>
</template>
<script>
export default {
  name: 'pagination',
  props: {
    total: {
      type: Number
    },
    curPage: {
      type: Number
    },
    perPage: {
      type: Number
    }
  },
  data() {
    return {}
  },
  methods: {
    handleSizeChange(val) {
      this.$emit('size-change', val)
    },
    handleCurrentChange(val) {
      this.$emit('current-change', val)
    }
  }
}
</script>
<style lang="scss" scoped>
#pagination{
  height: 35px;
  margin-top: 10px;
  .el-pagination {
  float: right;
  ::v-deep .number{
    color: #db3e3e!important;
  }
  ::v-deep .active{
    background: #db3e3e!important;
    color: #fff!important;
  }
}
}

</style>