<template>
  <div class="header-tops">
    <div class="tops txt-center">
      <div class="flex-row txt-center tops-cont">
        <div class="flex-cell">欢迎使用甘肃省家政服务业信用信息平台</div>
        <div class="m-l-15 cursor-pointer" @click="$router.push({name: 'newsShow', params: {id: 21, menuCode: 10,menuName: '网站声明'}})">网站声明</div>
        <div class="m-l-15 cursor-pointer" @click="$router.push({name: 'newsShow', params: {id: 22, menuCode: 11,menuName: '关于我们'}})">关于我们</div>
      </div>
    </div>
    <div class="header_top flex-row txt-center">
      <img class="logo" src="@/assets/images/logo.png" alt="" />
      <div class="flex-cell"></div>
      <div class="search-wrap">
        <div class="flex-row txt-center tabs">
          <div
            :class="idx == 1 ? 'font-sel' : ''"
            @click="doClickTabs('统一社会信用代码', 1)"
          >
            统一社会信用代码
          </div>
          <div
            :class="idx == 2 ? 'font-sel' : ''"
            @click="doClickTabs('企业信息', 2)"
          >
            企业信息
          </div>
          <div
            :class="idx == 3 ? 'font-sel' : ''"
            @click="doClickTabs('站内文章', 3)"
          >
            站内文章
          </div>
          <div class="flex-cell"></div>
        </div>
        <div style="margin-top: 15px">
          <el-input :placeholder="'请输入' + placeholder" v-model="value">
            <el-button
              class="search-btn"
              slot="append"
              type="danger"
              icon="el-icon-search"
              @click="doSearch()"
              >搜索</el-button
            >
          </el-input>
        </div>
      </div>
    </div>
    <div class="menu">
      <div class="flex-row menu-box">
        <div
          class="flex-cell flex-row txt-center menu-child"
          v-for="(item, index) in menuArr"
          :key="index"
          @click="doOpenPage(item)"
        >
          <span v-if="index != 0">|</span>
          <div class="flex-cell txt-center cursor-pointer">{{ item.txt }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "headerTop",
  components: {},
  props: {
    isSel: Number,
    isHome: Boolean,
    isSearch: Boolean,
  },
  data() {
    return {
      value: "",
      placeholder: "统一社会信用代码",
      idx: 1,
      menuArr: [
        {
          txt: "首页",
          router: "/",
        },
        {
          txt: "政策法规",
          router: "/newsLists/7",
        },
        {
          txt: "行业资讯",
          router: "/newsLists/3",
        },
        {
          txt: "标准规范",
          router: "/newsLists/4",
        },
        {
          txt: "信用承诺",
          router: "/newsLists/5",
        },
        {
          txt: "信用服务",
          router: "/newsIndex",
        },
        {
          txt: "诚信故事",
          router: "/newsLists/8",
        },
        {
          txt: "推树活动",
          router: "/newsLists/2",
        },
      ],
    };
  },
  mounted() {
    // @@ 初始化
    l_initData(this);
  },
  methods: {
    // 选择搜索分类
    doClickTabs(txt, idx) {
      this.placeholder = txt;
      this.idx = idx;
    },
    // 打开页面
    doOpenPage(item){
      if(item.router == '/' || item.router == '/newsIndex'){
        this.$router.push({ path: item.router})
      }else{
        this.$router.push({ path: item.router+'/'+item.txt })
      }
    },
    // 搜索
    doSearch(){
      const value = this.value
      this.value = ''
      if(this.idx == 3){
        this.$router.push({name: 'credit', params: {val: value}})
      }else{
        this.$router.push({name: 'search', params: {idx: this.idx, val: value}})
      }
    }
  },
};

// @@ 初始化
function l_initData(pg) {}
</script>
<style lang="scss" scoped>
.header-tops{
  background: #fff;
}
.tops {
  background: rgba(214, 213, 213, 0.27);
  font-size: 14px;
  height: 40px;
  .tops-cont {
    width: 1200px;
    margin: 0 auto;
  }
}
.header_top {
  width: 1200px;
  margin: 0 auto;
  padding-top: 50px;
  padding-bottom: 30px;
  
  .logo {
    width: 600px;
    height: 100px;
  }
  .search-wrap {
    font-size: 14px;
    width: 530px;
    .tabs {
      div {
        margin-right: 40px;
        cursor: pointer;
      }
      .font-sel {
        color: #db3e3e;
      }
    }
    .search-btn {
      background: #db3e3e !important;
      color: #fff;
      font-weight: bold;
      border-radius: 0;
      border: 1px solid #db3e3e !important;
    }
  }
}
.menu {
  background: #db3e3e;
  margin-bottom: 10px;
  .menu-box {
    width: 1200px;
    margin: 0 auto;
    color: #fff;
    height: 50px;
    span {
      color: #ec7259;
    }
    .menu-child:hover {
      background: #d23131;
      span {
        color: #d23131;
      }
    }
  }
}
</style>
