<template>
<div id="app">
  <header-top v-if="isShow" :isSel="0" :isHome="true" :isSearch="false" />
  <menu-custom v-if="isShow" />
  <router-view />
  <!-- <keep-alive>
  <router-view v-if="$route.meta.keepAlive"></router-view>
</keep-alive>
<router-view v-if="!$route.meta.keepAlive"></router-view> -->
  <footer-bottom v-if="isShow" />
</div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      isShow: true
    };
  },
  watch: {
    $route (val) {
      console.log()
      if(val.name == 'login') this.isShow = false
      if(val.name != 'login') this.isShow = true
    }
  },
  mounted(){
    
  }
};
</script>

<style lang="scss">
@import 'assets/aui.css';
@import 'style/font.css';

html, body{
  margin: 0!important;
  padding: 0!important;
  background: #f1f1f1;
}
#app {
  margin: 0 !important;
  height: 100%;
  min-width: 1080px;
  font-size: 18px;
}
.sortable-chosen, .sortable-ghost{
  background-color: #fff;
}

.pages{
  width: 1200px;
  margin: 0 auto;
}
</style>
