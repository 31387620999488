<template>
  <div class="menu">
    <div class="flex-col txt-center menu-child">
      <div class="txt-center menu-cell cursor-pointer" @click="$router.push({name: 'credit', params: {val: ''}})">
        <img src="@/assets/icon/search.png" alt="">
      </div>
      <div class="txt-center menu-cell cursor-pointer" @click="$router.push({ path: `/message` })">
        <img src="@/assets/icon/message.png" alt="">
      </div>
      <div class="txt-center menu-cell cursor-pointer" @click="$router.push({ path: `/` })">
        <img src="@/assets/icon/home.png" alt="">
      </div>
      <div class="menu-tel cursor-pointer">
        <div class="txt-center menu-cell">
          <img src="@/assets/icon/tel.png" alt="">
        </div>
        <div class="praw-tel">
          <i class="iconfont icondianhua2"></i>
          0931-8869008转8009
        </div>
      </div>
      <div class="menu-wx cursor-pointer">
        <div class="txt-center menu-cell">
          <img src="@/assets/icon/wx.png" alt="">
        </div>
        <img class="praw-wx" src="@/assets/images/wx-code.jpg" alt="">
      </div>
      <div class="txt-center menu-cell cursor-pointer" @click="doTops()">
        <img src="@/assets/icon/top.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      scrollTop: ''
    };
  },
  mounted() {},
  methods: {
    
    // 置顶
    doTops(){
      let top = document.documentElement.scrollTop || document.body.scrollTop
      const timeTop = setInterval(() => {
        document.body.scrollTop = document.documentElement.scrollTop = top -= 50
        if (top <= 0) {
          clearInterval(timeTop)
        }
      }, 10)
    },
  },
};

</script>
<style lang="scss" scoped>
.menu {
  position: fixed;
  right: 0;
  bottom: 120px;
  z-index: 1000000;
  box-shadow: 0 0 10px #ccc;
  background: #fff;
  width: 60px;
  .menu-child{
    width: 60px;
    .menu-cell{
      border-bottom: 1px dotted #ccc;
      width: 60px;
      height: 50px;
      img{
        width: 50%;
        
      }
    }
    .menu-tel{
      .praw-tel{
        display: none;
        height: auto;
        padding-top: 20px;
        padding-bottom: 20px;
        text-align: center;
        background: #fff;
        font-size: 26px;
        color: #db3e3e;
        width: 260px;
        border-left: 10px solid #db3e3e;
        font-family: Franklin Gothic Medium,Arial Narrow,Arial,sans-serif;
        font-style: italic;
        position: absolute;
        right: 70px;
        top: 150px;
        // opacity: 0.6;
        .iconfont{
          font-size: 24px;
        }
      }
    }
    .menu-tel:hover{
      .praw-tel{
        display: block;
      }
    }

    .menu-wx{
      .praw-wx{
        display: none;
        background: #fff;
        width: 160px;
          height: 160px;
        position: absolute;
        right: 70px;
        top: 150px;
        // opacity: 0.6;
      }
    }
    .menu-wx:hover{
      .praw-wx{
        display: block;
      }
    }
  }
}
</style>
