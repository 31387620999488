
<template>
  <div class="upload-file">
    <el-upload
      :class="hideUpload?'hideUpload':''"
      :action="uploadFileUrl"
      list-type="picture-card"
      :on-success="handleUploadSuccess"
      :before-upload="handleBeforeUpload"
      :on-error="handleUploadError"
      :on-exceed="handleExceed"
      name="file"
      :file-list="fileList"
      ref="upload"
    >
      <i slot="default" class="el-icon-plus"></i>
      <div slot="file" slot-scope="{ file }">
        <img
          class="el-upload-list__item-thumbnail"
          :src="file.url"
          alt=""
        />
        <div class="closeIcon" @click="doDel(file)">
          <i class="el-icon-error"></i>
        </div>
      </div>
    </el-upload>
    <el-dialog  :close-on-click-modal="false"
      title=""
      width="60%"
      :visible.sync="dialogVisible"
      append-to-body
    >
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "FileUpload",
  props: {
    // 字段名称
    field: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      uploadFileUrl: process.env.VUE_APP_BASE_API + "api/company/upload", // 上传的图片服务器地址
      hideUpload: false,
      fileList: [],
      dialogImageUrl: "",
      dialogVisible: false,
      fileType: ["jpg", "jpeg", "png"],
      fileSize: 5
    };
  },
  watch: {
    fileList: {
      handler(val) {
        if(val.length>0){
          this.hideUpload = true
        }else{
          this.hideUpload = false
        }
      },
      deep: true,
      immediate: true,
    }
  },
  methods: {
    // 上传前校检格式和大小
    handleBeforeUpload(file) {
      // 校检文件类型
      if (this.fileType) {
        let fileExtension = "";
        if (file.name.lastIndexOf(".") > -1) {
          fileExtension = file.name.slice(file.name.lastIndexOf(".") + 1);
        }
        const isTypeOk = this.fileType.some((type) => {
          if (file.type.indexOf(type) > -1) return true;
          if (fileExtension && fileExtension.indexOf(type) > -1) return true;
          return false;
        });
        if (!isTypeOk) {
          this.$message.error(
            `文件格式不正确, 请上传${this.fileType.join("/")}格式文件!`
          );
          return false;
        }
      }
      // 校检文件大小
      if (this.fileSize) {
        const isLt = file.size / 1024 / 1024 < this.fileSize;
        if (!isLt) {
          this.$message.error(`上传文件大小不能超过 ${this.fileSize} MB!`);
          return false;
        }
      }
    },
    // 文件个数超出
    handleExceed() {
      this.$message.error(`上传文件数量不能超过 ${this.limit} 个!`);
    },
    // 上传失败
    handleUploadError(err) {
      this.$message.error("上传失败, 请重试");
    },
    // 上传成功回调
    handleUploadSuccess(res, file, fileList) {
      var that = this
      that.fileList = fileList
      that.$message.success("上传成功");
      that.$emit('watchFileList', that.field, res.data)
    },
    doDel(file){
      this.fileList =[]
      this.$emit('deleteFile', this.field)
    },
  },
};
</script>

<style scoped lang="scss">
 ::v-deep .hideUpload .el-upload--picture-card {
  display: none;
}
::v-deep .el-upload-list__item{
  margin: 20px 32px 0 0;
}
.closeIcon{
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  .el-icon-error{
    font-size: 30px;
    color: #f00
  }
}
</style>