import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    // :: 首页
    {
        path: '/',
        name: 'Index',
        component: () =>
            import ('@/views/Home/index.vue'),
        hidden: true,
    },
    // :: 分类服务
    {
        path: '/serviceType',
        name: 'serviceType',
        component: () =>
            import ('@/views/service/type.vue'),
        hidden: true,
    },
    // 企业列表
    {
        path: '/serviceLists/:id',
        name: 'serviceLists',
        component: () =>
            import ('@/views/service/lists.vue'),
        hidden: true,
    },
    // 企业详情
    {
        path: '/serviceShow/:id/:title/:cid',
        name: 'serviceShow',
        component: () =>
            import ('@/views/service/show.vue'),
        hidden: true,
    },
    // 新闻频道
    {
        path: '/newsIndex',
        name: 'newsIndex',
        component: () =>
            import ('@/views/news/index.vue'),
        hidden: true,
    },
    // 新闻列表
    {
        path: '/newsLists/:idx/:txt',
        name: 'newsLists',
        meta: {
            // keepAlive: true,
            // noCache: false,
        },
        component: () =>
            import ('@/views/news/lists.vue'),
        hidden: true,
    },
    {
        path: '/newsListsOthers/:idx/:txt',
        name: 'newsListsOthers',
        // meta: {
        //     keepAlive: true,
        //     noCache: false,
        // },
        component: () =>
            import ('@/views/news/listsOthers.vue'),
        hidden: true,
    },
    // 新闻详情
    {
        path: '/newsShow/:id/:menuCode/:menuName',
        name: 'newsShow',
        component: () =>
            import ('@/views/news/show.vue'),
        hidden: true,
    },
    // 资料下载
    {
        path: '/downloadFiles',
        name: 'downloadFiles',
        component: () =>
            import ('@/views/downloadFiles/index.vue'),
        hidden: true,
    },
    // 信用
    {
        path: '/credit/:val',
        name: 'credit',
        component: () =>
            import ('@/views/credit/lists.vue'),
        hidden: true,
    },
    // 搜索
    {
        path: '/search/:idx/:val',
        name: 'search',
        component: () =>
            import ('@/views/search/lists.vue'),
        hidden: true,
    },
    // 搜索结果详情
    {
        path: '/searchShow/:id',
        name: 'searchShow',
        component: () =>
            import ('@/views/search/show.vue'),
        hidden: true,
    },
    // 留言
    {
        path: '/message',
        name: 'message',
        component: () =>
            import ('@/views/message/index.vue'),
        hidden: true,
    },
    // 注册
    {
        path: '/register',
        name: 'register',
        component: () =>
            import ('@/views/register/register.vue'),
        hidden: true,
    }
]

const router = new VueRouter({
    routes,
})

export default router