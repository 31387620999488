<template>
  <div class="footer">
    <div class="footer-content">
      <div class="flex-row txt-center footer-menu">
        <div class="txt-center flex-cell">
          <i class="iconfont iconsvg-"></i>
          <div class="m-l-10">班子建设好</div>
        </div>
        <div class="txt-center flex-cell">
          <i class="iconfont iconsvg-2"></i>
          <div class="m-l-10">团结教育好</div>
        </div>
        <div class="txt-center flex-cell">
          <i class="iconfont iconfuwu"></i>
          <div class="m-l-10">服务发展好</div>
        </div>
        <div class="txt-center flex-cell">
          <i class="iconfont iconguifandunpai"></i>
          <div class="m-l-10">自律规范好</div>
        </div>
      </div>
      <div class="footer-cont">
        <div class="flex-row txt-center m-t-15">
          <div class="flex-cell flex-row txt-center">
            <i class="iconfont iconwangye"></i>
            <div class="flex-cell">省家协官方网址：www.gssjx.cn</div>
          </div>
          <div class="flex-cell flex-row txt-center">
            <i class="iconfont iconwangye"></i>
            <div class="flex-cell">家政信用平台网址：cxpt-gssjx.cn</div>
          </div>
          <div class="flex-cell flex-row txt-center">
            <i class="iconfont iconweixin"></i>
            <div class="flex-cell">官方微信号：gssjxmsc</div>
          </div>
          <div class="flex-cell flex-row txt-center">
            <i class="iconfont iconmail"></i>
            <div class="flex-cell">邮箱：gssjtfwhyxh@163.com</div>
          </div>
        </div>
        <div class="flex-row txt-center m-t-15">
          <div class="flex-cell flex-row txt-center">
            <i class="iconfont icondianhua1"></i>
            <div class="flex-cell">协会电话：0931-8869008转8009</div>
          </div>
          <div class="flex-cell flex-row txt-center">
            <i class="iconfont icondianhua1"></i>
            <div class="flex-cell">服务电话：13369313489</div>
          </div>
          <div class="flex-cell flex-row txt-center">
            <i class="iconfont icondianhua1"></i>
            <div class="flex-cell">服务电话：13359476913</div>
          </div>
          <div class="flex-cell"></div>
        </div>
      </div>
      <div class="footer-other flex-row txt-center">
        <div class="flex-cell">
          <div class="flex-row txt-center cursor-pointer" @click="doOpenWindow('http://bszs.conac.cn/sitename?method=show&id=886F5332CA098FBEE05310291AAC81FF')">
            <img class="icons" src="@/assets/icon/yuming.png" alt="">
            <div class="flex-cell"> 域名备案编号CA284020000408147200002号 </div>
          </div>
          <div class="flex-row txt-center m-t-15 cursor-pointer" @click="doOpenWindow('https://beian.miit.gov.cn')">
            <img class="icons" src="@/assets/icon/yuming.png" alt="">
            <div class="flex-cell">陇ICP备19002525号-2</div>
          </div>
          <div class="flex-row txt-center m-t-15 cursor-pointer" @click="doOpenWindow('http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=62300102000077')">
            <img class="icons" src="@/assets/icon/guohui.png" alt="">
            <div class="flex-cell"> 甘公网安备62300102000077号 </div>
          </div>
          <div class="flex-row txt-center m-t-15">
            <i class="iconfont iconbanquan"></i>
            <div class="flex-cell">版权所有：甘肃省商务厅</div>
          </div>
          <div class="flex-row txt-center m-t-15">
            <i class="iconfont iconweibiaoti2"></i>
            <div class="flex-cell">业务指导单位：甘肃省商务厅</div>
          </div>
          <div class="flex-row txt-center m-t-15">
            <i class="iconfont iconbanquan"></i>
            <div class="flex-cell">承办单位：甘肃省家庭服务行业协会</div>
          </div>
          <div class="flex-row txt-center m-t-15">
            <i class="iconfont icondizhi"></i>
            <div class="flex-cell">单位地址：甘肃省兰州市城关区庆阳路149号亚欧国际大厦33楼</div>
          </div>
          <div class="flex-row txt-center m-t-15">
            <div class="flex-cell">建议您使用IE10+、FireFox、Google Chrome、搜狗等，分辨率1280*800及以上浏览本网站，获得更好用户体验。</div>
          </div>
        </div>
        <div class="txt-center erweima">
          <div class="flex-col txt-center m-lr-20">
            <img src="@/assets/images/img-code.jpg" alt="">
            <div class="m-t-5">家协公众号</div>
          </div>
          <div class="flex-col txt-center m-lr-20">
            <img src="@/assets/images/wx-code.jpg" alt="">
            <div class="m-t-5">家政小程序</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      searchKey_title: "",
    };
  },
  mounted() {
    
  },
  methods: {
    doOpenWindow(url){
      window.open(url)
    }
  },
};

</script>
<style lang="scss" scoped>
.footer {
  margin-top: 30px;
  background: #db3e3e;
  padding-top: 20px;
  padding-bottom: 100px;
  color: #fff;
  min-width: 1200px;
  font-size: 14px;
  .footer-content{
    width: 1200px;
    margin: 0 auto;
    .footer-menu{
      padding: 30px 0;
      border-bottom: 1px solid #fff;
      .iconfont{
        font-size: 40px
      }
    }
    .footer-cont{
      margin: 30px 0;
      .iconfont{
        font-size: 16px;
        margin-right: 5px;
      }
    }
    .footer-other{
      .icons{
        width: 20px;
        height: 20px;
        margin-right: 5px;
      }
      .iconfont{
        font-size: 16px;
        margin-right: 5px;
      }
      .erweima{
        width: 350px;
        img{
          width: 100px;
          height: 100px;
          // opacity: .5;
        }
        // img:hover{
        //   opacity: 1;
        // }
      }
    }
  }
  
}
</style>
