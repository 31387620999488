import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    crumbsCustom: []
  },
  mutations: {
    CRUMBS_CUSTOM(state, value){
			state.crumbsCustom = value
		}
  },
  actions: {
  },
  modules: {
  }
})

export default store
